.successWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.succesContentWrapper {
  max-width: 450px;
  width: 100%;
  padding: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.successBody {
  background-color: var(--light);
  border-radius: 10px;
  padding: 15px;
}
.successBody .successBodyContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.successBody .successBodyContent:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}
.successAnimation {
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.successHeader .mainHeading {
  text-align: center;
  margin: 0.5rem auto;
}
.succesContentWrapper .successFooter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  justify-content: center;
}
