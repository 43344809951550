@import url(fonts/font.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

.ant-layout {
  background: transparent !important;
}

:root {
  --white: #ffffff;
  --light: #f5f5f5;
  --silver: #eeeeee;
  --grey: #808080;
  --dark: #333333;
  --darkSilver: #464646;
  --black: #000000;
  --blackOpacity10: rgba(0, 0, 0, 0.1);
  --whiteOpacity10: rgba(255, 255, 255, 0.1);
  --success: #4bb543;
  --danger: #ff0000;
  --primary: #04bbfb;
  --pending: #f29339;
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: normal;
}

/* ------------property classes-------------- */

.padd30 {
  padding: 30px;
}

.bgLight {
  background-color: var(--light);
}

.radius10 {
  border-radius: 10px;
}

.cursorPointer {
  cursor: pointer;
}

.boxShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.m0 {
  margin: 0 !important;
}

.mt30 {
  margin-top: 30px;
}

.border0 {
  border: none;
}

.mL5 {
  margin-left: 5px;
}

.mT30 {
  margin-top: 30px;
}

/* ------------scrollbar-------------- */

.commonScrollbar::-webkit-scrollbar {
  width: 5px;
}

.commonScrollbar::-webkit-scrollbar-track {
  padding-left: 5px;
}

.commonScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 30px;
}

/* ------------notFound-------------- */

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
}

.notFound .commonButton {
  margin-top: 30px;
}

/* ------------Button-------------- */

.ant-btn-default {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: none;
  font-size: 14px;
  padding: 4px 15px;
}

.ant-btn {
  height: auto;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
}

/* ------------Button-------------- */

.disableBtn {
  background-color: #cac9c6 !important;
}

.disableBtn :hover {
  color: #ffffff !important;
}

.disableBtnWrapper .ant-btn-default:hover {
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.ant-btn-default {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-btn {
  height: auto;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
}

.squareBtn.ant-btn,
.squareBtn {
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.commonButton {
  padding: 15px 70px;
  border-radius: 5px;
  background-color: var(--dark);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.commonButton:hover {
  color: var(--dark) !important;
  background-color: transparent !important;
  border: 2px solid var(--dark) !important;
}

.commonButtonSmall {
  padding: 8.5px 39.5px;
  border-radius: 5px;
  background-color: var(--dark);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.commonButtonSmall:hover {
  color: var(--dark) !important;
  background-color: transparent !important;
  border: 2px solid var(--dark) !important;
}

.squareBtn.ant-btn:hover svg path,
.squareBtn:hover svg path {
  fill: var(--primary);
}

.squareBtn.ant-btn.remove,
.squareBtn.remove {
  color: var(--danger);
  background-color: transparent;
  border: 1px solid var(--danger);
}

.squareBtn.ant-btn.remove svg path,
.squareBtn.remove svg path {
  fill: var(--danger);
}

.squareBtn.ant-btn.remove:hover,
.squareBtn.remove:hover {
  background-color: var(--danger);
  border: 1px solid transparent;
}

.squareBtn.ant-btn.remove:hover svg path,
.squareBtn.remove:hover svg path {
  fill: var(--white);
}

/* -----------------Success button-------------- */

.commonButton.successBtn {
  background: var(--success);
}

.commonButton.successBtn:hover {
  color: var(--success) !important;
  border-color: var(--success) !important;
}


/* ------------Heading-------------- */

.mainHeading {
  color: var(--dark);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mainHeadingPara {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
}

.subHeading {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.subInnerHeading {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.subInnerHeadingPara {
  color: var(--grey);
  font-size: 16px;
  font-weight: normal;
}

/* ------------body-------------- */

.commonBody {
  padding: 30px 30px 0;
  width: calc(100% - 85px);
  float: right;
  height: calc(100vh - 161px);
  display: flex;
  flex-direction: column;
}

.centerBody {
  height: calc(100vh - 161px);
  overflow: auto !important;
}

/* ------------Form-------------- */

.ant-form-item input,
.inputLayout,
input {
  font-size: 15px;
  color: var(--dark);
  border: 1px solid var(--blackOpacity10);
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
}

::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.ant-form-item .ant-input-affix-wrapper input {
  border: none;
}

.ant-form-item .ant-input-affix-wrapper {
  border: 1px solid var(--blackOpacity10);
}

.ant-form-item input:focus,
.ant-form-item input:active,
.ant-form-item input:hover,
.ant-form-item input:focus-within,
.inputLayout:focus,
.inputLayout:active,
.inputLayout:hover,
.inputLayout:focus-within,
input:focus,
input:active,
input:hover,
input:focus-within {
  border: 1px solid var(--dark);
  outline: none;
  box-shadow: none;
}

.ant-form-item .ant-input-affix-wrapper input:focus,
.ant-form-item .ant-input-affix-wrapper input:active,
.ant-form-item .ant-input-affix-wrapper input:hover,
.ant-form-item .ant-input-affix-wrapper input:focus-within {
  border: none;
  outline: none;
  box-shadow: none;
}

.ant-form-item .ant-input-affix-wrapper:focus,
.ant-form-item .ant-input-affix-wrapper:active,
.ant-form-item .ant-input-affix-wrapper:hover,
.ant-form-item .ant-input-affix-wrapper:focus-within {
  border: 1px solid var(--dark);
  outline: none;
  box-shadow: none;
}

.errorMessage {
  color: var(--danger);
  font-size: 12px;
  display: block;
  margin-top: 5px;
  text-align: left;
}

/* ------------radio-------------- */

.ant-switch {
  min-width: 45px;
  height: 25px;
}

.ant-switch.ant-switch-checked {
  background-color: var(--primary) !important;
}

.ant-switch .ant-switch-handle {
  top: 5px;
  width: 14px;
  height: 14px;
  inset-inline-start: 5px;
}

.ant-radio-wrapper::after {
  display: none;
}

/* ------------Payment option radio-------------- */

.ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid var(--success);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--success);
  border-color: var(--success);
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--success);
}

.ant-radio-wrapper span.ant-radio+* {
  padding: 0;
}

.ant-radio-wrapper {
  margin: 0;
}

.ant-image-mask {
  display: none !important;
}

/* ------------table-------------- */

.ant-table-wrapper .ant-table-content .ant-table-cell {
  padding: 15px;
}

.ant-table-wrapper .ant-table-content th.ant-table-cell {
  background-color: var(--dark);
  color: var(--white);
  font-size: 16px;
}

.ant-table-wrapper .ant-table-content th.ant-table-cell:before {
  display: none;
}

.ant-table-wrapper .ant-table-content td.ant-table-cell {
  font-size: 15px;
  color: var(--dark);
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 15px 0 0;
  display: none;
}

.ant-table-wrapper .ant-pagination .ant-pagination-item a {
  padding: 0 5px;
}

.ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: var(--dark);
  font-size: 15px;
}

.ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border-color: var(--dark);
  color: var(--dark);
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 1px solid var(--blackOpacity10);
}

/* ------------Footer-------------- */

.footerWrapper {
  padding: 15px 0;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 12px;
}

.footerWrapper p {
  text-align: center;
  color: var(--grey);
}

.footerWrapper span,
.footerWrapper span a {
  color: var(--dark);
  font-size: 12px;
  text-decoration: underline;
}

/* ------------Responsive Global Css------------ */

@media (max-width: 500px) {
  .mainHeading {
    font-size: 20px;
  }

  .subHeading {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .subInnerHeading {
    font-size: 16px;
  }

  .commonButton {
    padding: 12px 30px;
    font-size: 16px;
  }
}