.ec-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 20px;
  z-index: 1;
}

.ec-header-wrapper .ec-header-wrapper-right {
  display: flex;
  align-items: center;
}

.ec-header-wrapper .ec-header-wrapper-right .dropDown {
  width: max-content;
  background-color: var(--light);
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.ant-dropdown-menu {
  padding: 15px !important;
  border-bottom: 3px solid var(--primary);
}

.ant-dropdown-menu .chainList {
  padding: 5px 15px !important;
}

.ant-dropdown-menu .chainList:not(:last-child) {
  margin-bottom: 10px !important;
}

.chainList .ant-dropdown-menu-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 5px 0;
}

.chainList .ant-dropdown-menu-title-content svg {
  width: 20px;
  height: 100%;
}

.ec-component-wrapper.inner .ec-header-wrapper .basket-icon.active {
  position: relative;
}

.ec-component-wrapper.inner .ec-header-wrapper .basket-icon.active .ePNum {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--primary);
  padding: 3px 6px;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  font-weight: bold;
  text-shadow: 1px 1px 1px #00000070;
}

.ec-component-wrapper.inner .ec-header-wrapper .basket-icon.active svg path {
  fill: var(--primary);
}

.chainList .ant-dropdown-menu-title-content p {
  font-size: 16px;
}

.ec-header-wrapper .ec-header-wrapper-right .dropDown svg {
  width: 13px;
  transition: 0.3s all ease-in-out;
  margin: 0;
}

.ec-header-wrapper .ec-header-wrapper-right .subInnerHeading {
  margin-bottom: 0;
  font-size: 16px;
}

.ec-header-wrapper .ec-header-wrapper-right .dropDown svg path {
  fill: var(--dark);
}

.ec-component-wrapper.inner .ec-header-wrapper {
  position: unset;
  align-items: center;
  border-bottom: 1px solid var(--blackOpacity10);
  padding: 15px 30px;
}

.ec-header-wrapper svg {
  width: 30px;
  height: 100%;
  cursor: pointer;
  margin-left: 30px;
}

.ec-header-wrapper>.ant-image .ant-image-img {
  width: 100px;
  border-radius: 50%;
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 50%;
}

.ec-header-wrapper svg path {
  fill: var(--white);
}

.ec-component-wrapper.inner .ec-header-wrapper>.ant-image .ant-image-img {
  width: 75px;
}

.ec-component-wrapper.inner .ec-header-wrapper svg path {
  fill: var(--dark);
}

.ec-header-wrapper .cross-icon svg {
  width: 20px;
  height: 100%;
}

.ec-header-wrapper .cross-icon svg path {
  fill: var(--dark);
}

.ec-front-wrapper {
  text-align: center;
}

.mainPage,
.homebg {
  height: calc(100vh - 72px);
  width: 100%;
  position: relative;
}

.homebg .ant-image,
.homebg .ant-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.ec-component-wrapper.inner .mainPage {
  height: calc(100vh - 170px);
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  overflow: scroll;
}

.homebg .homeVideoBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.ec-header-wrapper .basketWrapper {
  transform: translate(308px);
  transition: 0.3s all ease-in-out;
}

.ec-header-wrapper .cross-icon {
  position: absolute;
  z-index: 2;
  top: 25px;
  right: 20px;
}

.ec-header-wrapper .basketWrapper.activeBasketWrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  transform: translate(0px);
  z-index: 1;
  background-color: var(--white);
  border-radius: 10px 0 0 10px;
}

.ec-front-content {
  position: absolute;
  top: 50%;
  left: 400px;
  transform: translate(-50%, -50%);
  text-align: left;
}

.ec-front-content h1 {
  color: var(--white) !important;
  font-size: 50px;
  margin: 0 0 30px;
  letter-spacing: 1px;
  font-weight: 600;
}

.ec-front-content h1,
.ec-front-content p {
  text-align: left;
  color: var(--white);
}

.ec-front-content p {
  margin: 0 0 50px;
  color: var(--light) !important;
  font-size: 20px;
  line-height: 32px;
  font-style: italic;
  font-weight: 400;
  width: 100%;
  max-width: 650px;
  letter-spacing: 0.5px;
}

.ec-front-content button {
  text-align: left;
  height: 50px;
}

.ec-front-content svg {
  transform: rotate(180deg);
  margin-left: 10px;
}

.ec-front-content svg path {
  fill: var(--white);
  transition: 0.2s all ease-in-out;
}

.ecCommonButton {
  padding: 15px 70px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.ecCommonButton:hover {
  color: var(--primary) !important;
  background-color: transparent !important;
  border: 2px solid var(--primary) !important;
}

.ecCommonButton:hover svg path {
  fill: var(--primary);
}

.ecProduct .productHeader {
  display: none;
}

.ecContainer {
  max-width: 1320px;
  margin: 0 auto;
}

.ecProduct .productListWrapper {
  box-shadow: none;
}

.ecProduct .productListContent .subInnerHeadingPara {
  color: var(--dark);
  font-weight: 600;
}

.ecProduct .productListContent .productInfo {
  color: var(--grey);
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.ecProduct .productListWrapper {
  padding-top: 0;
}

.ecProduct .productListWrapper .productListBody {
  height: unset;
  min-height: unset;
  max-height: unset;
  padding-top: 0;
}

.ecTabs {
  width: 100%;
  color: var(--dark);
  background-color: var(--light);
}

.ecTabs .ecTabsInner {
  margin: 0 auto;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.ecTabs .ecTabsInner .categoryTabs {
  padding: 10px 50px;
  cursor: pointer;
  border-radius: 5px;
}

.ecTabs .ecTabsInner .categoryTabs p {
  color: var(--dark);
  margin: 0;
}

.ecTabs .ecTabsInner .categoryTabs.active {
  background-color: var(--dark);
}

.ecTabs .ecTabsInner .categoryTabs.active p {
  color: var(--white);
}

.ecProduct .productWrapper {
  height: unset;
  padding-bottom: 30px;
}

.productHeadingWrapper {
  text-align: center;
  padding: 30px 0;
  text-transform: capitalize;
}

.ecProduct .productItemWrapper {
  flex: 0 0 25%;
  max-width: 25%;
}

.ec-footer-wrapper {
  padding: 10px 0;
  text-align: center;
}

.ec-footer-wrapper svg {
  width: 150px;
}

.ec-footer-wrapper p {
  font-size: 12px;
  font-weight: 600;
  color: var(--dark);
}

.ecFlexSpaceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.ecFlexSpaceWrapper .subInnerHeadingPara {
  font-size: 14px;
}

.ecAddressTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.ecAddressContentWrapper {
  display: flex;
  gap: 30px;
}

.ecBillingWrapper {
  max-width: 100%;
  width: 100%;
  height: 65vh;
  overflow: auto;
  position: relative;
}

.ecBillingWrapper label,
.ant-form-item-label {
  white-space: nowrap;
  max-width: 100% !important;
}

.ant-checkbox-wrapper span {
  white-space: pre-wrap;
}

.ecBillingWrapper .ant-form-item-control {
  max-width: 100%;
}

.ecShippingWrapper {
  max-width: 500px;
  height: min-content;
}

.ecShippingWrapper .ecShippingInfo {
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 18px;
}

.ecShippingWrapper .commonButton {
  margin-top: 30px;
}

.ecShippingWrapper .ShippingHeading {
  margin: 10px 0;
}

.ecShippingWrapper .ShippingCost {
  border-top: 1px solid var(--blackOpacity10);
  border-bottom: 1px solid var(--blackOpacity10);
  padding: 10px 0;
  margin: 10px 0;
}

@media (min-width: 1500px) {
  .ecProduct .productItemWrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1500px) {
  .ecProduct .productItemWrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1200px) {
  .ecProduct .productItemWrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ecAddressContentWrapper .ant-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .ecAddressContentWrapper .ant-col-12 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .ecAddressContentWrapper {
    flex-direction: column;
  }

  .ecShippingWrapper {
    max-width: 100%;
  }

  .ec-front-content {
    left: 50%;
    text-align: center;
    width: 100%;
  }

  .ec-front-content h1,
  .ec-front-content p {
    text-align: center;
  }

  .ec-front-content h1 {
    margin: 0 0 20px;
    font-size: 35px;
  }

  .ec-front-content p {
    font-size: 18px;
    line-height: 27px;
    margin: 0 auto 35px;
    max-width: 90%;
  }

  .ecCommonButton {
    font-size: 16px;
    padding: 12px 40px;
  }
}

@media (max-width: 600px) {
  .ecAddressContentWrapper .ant-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .ecProduct .productItemWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ecBillingWrapper {
    height: auto;
    padding: 20px;
  }

  .ec-header-wrapper,
  .ec-component-wrapper.inner .ec-header-wrapper {
    position: unset;
    padding: 15px;
  }

  .ec-front-content h1 {
    font-size: 27px;
  }

  .ec-front-content p {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
  }

  .ec-header-wrapper>.ant-image .ant-image-img,
  .ec-component-wrapper.inner .ec-header-wrapper>.ant-image .ant-image-img {
    width: 65px;
  }

  .ec-header-wrapper svg {
    width: 25px;
    margin-left: 10px;
  }

  .ec-header-wrapper svg path {
    fill: #333333;
  }

  .ec-header-wrapper .ec-header-wrapper-right .dropDown {
    padding: 5px 10px;
    position: static;
    text-align: center;
    background-color: var(--light);
  }

  .ec-header-wrapper .ec-header-wrapper-right .dropDown .subInnerHeading {
    font-size: 14px;
  }

  .productPage {
    padding: 0 15px;
  }

  .ecTabs .ecTabsInner {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .ecTabs .ecTabsInner .categoryTabs {
    width: 100%;
    border-radius: 0px;
  }
}


.loaderCenterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.productWrapper {
  /* padding: 30px; */
  height: 100vh;
}

.productWrapper .ant-image,
.productWrapper .ant-image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productContent {
  display: flex;
  gap: 30px;
  /* height: calc(100vh - 140px); */
  height: 100vh;
}

.productHeader {
  display: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: var(--dark);
}

.productHeader>svg {
  width: 22px;
  height: auto;
}

.productHeader .left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.productHeader .left .mainHeadingPara {
  color: var(--light);
  margin: 0 0 0 5px;
}

.productHeader .left svg path,
.productHeader .right svg path {
  fill: var(--light);
}

.productHeader .right>svg {
  margin: 0 0 0 0;
}

.categoryWrapper {
  background-color: var(--dark);
  border-radius: 0 10px 10px 0;
  position: relative;
  width: 100%;
  max-width: 168px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.categoryProfile .categoryProfileImg {
  width: 75px;
  height: 75px;
  margin: 30px auto 15px;
}

.categoryProfile .categoryProfileImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--primary);
}

.categoryProfile .categoryProfileContent {
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--whiteOpacity10);
}

.categoryProfile .categoryProfileContent .subInnerHeading {
  margin-bottom: 0px;
  color: var(--white);
}

.categoryProfile .categoryProfileContent .subHeadingPara {
  color: var(--grey);
  display: block;
}

.categoryWrapper .categoryContent .subHeading {
  text-align: center;
  color: var(--white);
  padding-top: 30px;
}

.categoryWrapper .categoryContent .categoryTab {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 272px);
  max-height: 400px;
  overflow: auto;
}

.categoryWrapper .categoryTab .categoryTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 15px 0;
  margin: 0 15px 15px;
  border-radius: 10px;
  background-color: var(--darkSilver);
}

.categoryWrapper .categoryTab .categoryTabs:last-child {
  margin: 0 15px 0;
}

.categoryWrapper .categoryTab .categoryTabs p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  text-align: center;
  margin-top: 5px;
  max-width: 150px;
  color: var(--light);
}

.categoryWrapper .categoryTab .categoryTabs .categoryTabsImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid var(--blackOpacity10);
}

.categoryWrapper .categoryTab .categoryTabs .categoryTabsImg .ant-image-mask,
.categoryWrapper .categoryTab .categoryTabs .categoryTabsImg .ant-image-img {
  border-radius: 50%;
  background-color: var(--white);
}

.categoryWrapper .categoryTab .categoryTabs.active {
  background-color: var(--primary);
}

.categoryWrapper .categoryTab .categoryTabs.active svg path,
.categoryWrapper .categoryTab .categoryTabs.active p {
  fill: var(--white);
  color: var(--white);
}

.productListWrapper {
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.productListWrapper .productListHeader {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--blackOpacity10);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productListWrapper .productListHeader input {
  max-width: 600px;
  width: 100%;
  border-color: rgb(3, 3, 3, 0.3);
  margin: 0 30px;
}

.productListWrapper .productListHeader .searchIcon {
  display: none;
}

.productListHeader .mainHeading {
  margin-bottom: 0;
}

.productListWrapper .productListHeader svg {
  height: 100%;
  width: 25px;
}

.productListWrapper .productListBody {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  min-height: calc(100vh - 135px);
  max-height: 400px;
  overflow: auto;
  align-content: flex-start;
}

.productItemWrapper {
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
}

.productItemInner {
  border-radius: 10px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.subCatListWrapper {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
}

.subCatListWrapper .productListImage {
  width: 150px !important;
}

.productListImage {
  width: 100%;
  height: 150px;
  margin: auto;
  background-color: var(--light);
  border-radius: 10px;
}

.productListBody .productListImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.productListContent .subInnerHeadingPara {
  margin: 15px 0 10px;
  font-size: 18px;
  text-transform: capitalize;
}

.productItemWrapper .subInnerHeading {
  margin-bottom: 0;
}

.productListBody .productListBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.productListBody .productListBottom .commonButtonSmall {
  padding: 7px 14px;
  font-size: 15px;
  background-color: var(--primary);
  border-width: 1px;
}

.productListBody .productListBottom .commonButtonSmall:hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-width: 1px !important;
}

.productListBody .productListBottom .commonButtonSmall:disabled {
  background-color: var(--grey) !important;
  pointer-events: none;
  opacity: 0.5;
}

.productListBody .productListBottom .commonButtonSmall.remove {
  color: var(--danger) !important;
  border-color: var(--danger) !important;
  border-width: 1px !important;
  background-color: transparent !important;
}

.productListBody .productListBottom .commonButtonSmall.remove:hover {
  color: var(--white) !important;
  background-color: var(--danger) !important;
}

/* --------------------basket---------------------- */

.basketWrapper {
  padding: 20px;
  border-radius: 10px;
  width: 325px;
  min-width: 308px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.basketWrapper>.subHeading {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--blackOpacity10);
}

.basketItemWrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 272px);
  max-height: 330px;
  overflow: auto;
}

.colorBlack {
  color: black !important;
}

.basketConProWrapper {
  padding: 10px 0;
  border-bottom: 1px solid var(--blackOpacity10);
}

.basketConProWrapper .basketConProTitle {
  font-size: 12px;
  text-transform: capitalize;
  color: var(--grey);
  display: block;
  text-align: right;
}

.basketItemWrapper .basketItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basketItemWrapper .basketItem:first-child {
  padding-top: 0;
}

.basketItemWrapper .basketItem:last-child {
  padding-bottom: 0;
}

.basketItemWrapper .basketConProWrapper:last-child {
  border-bottom: none;
}

.basketItemWrapper .basketItemPic {
  width: 60px;
  height: 60px;
  background-color: var(--light);
  border-radius: 10px;
}

.basketItemWrapper .basketItemPic img,
.ant-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.basketItemWrapper .basketItemContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: auto;
  margin-left: 15px;
}

.basketItemWrapper .basketItem .basketItemBtnWrapper {
  display: flex;
  /* gap: 5px; */
  align-items: center;
}

.basketItemWrapper .basketItem .basketItemBtnWrapper .subInnerHeadingPara {
  margin: 0 5px;
}

.basketItemWrapper .basketItem .basketItemBtnWrapper .ant-btn-default {
  padding: 2px 8px;
}

.basketItemWrapper .basketItem .subInnerHeadingPara {
  font-size: 14px;
  text-transform: capitalize;
}

.basketItemWrapper .basketItem .subInnerHeading {
  font-size: 14px;
}

.basketItemWrapper .basketRemoveItem.squareBtn.remove {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.basketCheckoutWrapper {
  width: 100%;
  padding: 0 20px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -20%);
}

.basketCheckoutWrapper .commonButton {
  width: 100%;
}

.basketAmountWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.basketButtonWrapper {
  padding: 12px 45px;
  border-radius: 30px;
  border: none;
  outline: none;
  transition: 0.3s all ease-in-out;
  background: #00a3dc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

/* profile modal  */
.userProfile {
  width: 50px;
  height: 50px;
  background-color: var(--dark);
  border-radius: 50%;
  border: 2px solid var(--grey);
}

.profileComponent {
  position: absolute;
  top: 110px;
  right: 85px;
  max-width: 409px;
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 3;
}

.profileheader {
  max-width: 409px;
  width: 100%;
  height: 100px;
  background-color: var(--dark);
  border-radius: 10px 10px 0 0;
}

.profileBottom {
  padding: 0 30px;
}

.profileComponent .userProfile {
  width: 100px;
  height: 100px;
}

.profileBodyContent {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--blackOpacity10);
  padding-bottom: 30px;
}

.profileBodyContent .mainHeading {
  margin-top: 10px;
}

.profileFooter {
  border-top: 1px solid var(--blackOpacity10);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.profileFooter p {
  font-size: 18px;
  color: var(--dark);
  font-weight: 600;
  margin-left: 15px;
}

.profileFooter .footerBtn {
  display: flex;
}

/* -------------------Responsive design---------------- */

@media (min-width: 1500px) {
  .productItemWrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1500px) {
  .productItemWrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1200px) {
  .productItemWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .productHeader {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productHeader .right.active svg path {
    fill: var(--primary);
  }

  .displayBlock {
    display: block !important;
  }

  .productHeader div {
    cursor: pointer;
  }

  .productListWrapper {
    padding: 0 15px;
  }

  .productListWrapper .productListHeader {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .productListWrapper .productListHeader input {
    display: none;
  }

  .productListWrapper .productListHeader .searchIcon {
    display: block;
    margin: 0 10px 0 auto;
  }

  .productListWrapper .productListHeader svg {
    width: 20px;
  }

  .productListWrapper .productListBody {
    padding-top: 15px;
  }

  .categoryWrapper {
    transform: translateX(-168px);
    transition: 0.3s all ease-in-out;
    position: fixed;
    z-index: 1;
    border-radius: 0;
    height: calc(100vh - 54px);
  }

  .categoryWrapper.activeCategoryWrapper {
    transform: translateX(0px);
  }

  .basketWrapper {
    transform: translateX(250px);
    transition: 0.3s all ease-in-out;
    position: fixed;
    z-index: 1;
    border-radius: 0;
    height: calc(100vh - 54px);
    width: 250px;
    min-width: 250px;
    background-color: var(--white);
    right: 0;
    transform-origin: 0% 100%;
  }

  .basketWrapper.activeBasketWrapper {
    transform: translateX(0px);
  }

  .basketWrapper>.subHeading {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .categoryHeader .categoryProfileImg {
    width: 50px;
    height: 50px;
    margin: 15px auto 10px;
  }

  .categoryProfile .categoryProfileContent {
    padding-bottom: 15px;
  }

  .categoryWrapper .categoryContent .subHeading {
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .productListWrapper {
    box-shadow: none;
  }

  .productItemWrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .productListContent .subInnerHeadingPara {
    margin: 10px 0 5px;
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .productItemWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .productItemWrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .productContent {
    height: auto;
  }
}